import { AxiosGet, AxiosPost } from "../utils/axios/AxiosCall";
import { 
  CUSTOMER_URL, 
  CUSTOMER_SUBSCRIPTION_URL, 
  CUSTOMER_API_KEY_GEN_URL 
} from "../configs/APIConfig";

/***
 * This method will fetch list of customer only
 */
export const GetCustomerList = async () => {
  try {
    const result = await AxiosGet(
      CUSTOMER_URL + "/" + sessionStorage.getItem("companyId")
    );
    if (result.status === 200) {
      return result.data.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/***
 * This method will create a new customer
 */
export const CreateNewCustomer = async (values) => {
  try {
    values.userType = "CUSTOMER";
    values.companyId = sessionStorage.getItem("companyId");
    const result = await AxiosPost(CUSTOMER_URL, values);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/****
 *
 */
export const GetCustomerSubscription = async (customerId) => {
  try {
    const result = await AxiosGet(
      CUSTOMER_SUBSCRIPTION_URL.replace("[customerId]", customerId)
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const UpdateCustomerSubscription = async (customerId, details) => {
  try {
    const result = await AxiosPost(
      CUSTOMER_SUBSCRIPTION_URL.replace("[customerId]", customerId),
      details
    );
    console.log(result);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const GenerateNewApiKey = async () => {
  try {
    const result = await AxiosPost(
      CUSTOMER_API_KEY_GEN_URL.replace("[customerId]", sessionStorage.getItem("id")),
      { companyId: sessionStorage.getItem("companyId") }
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const GetApiKey = async () =>{
  try {
    const result = await AxiosGet(
      CUSTOMER_API_KEY_GEN_URL.replace("[customerId]", sessionStorage.getItem("id"))
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
}