import React from 'react'
import { useEffect, useState } from 'react';
import Layout from '../Layout';
import ListComponent from '../../../components/crud/ListComponent';
import { InLogsTableList } from '../../../utils/dataTableList/inlogs';
import { GetInLogs } from '../../../services/DeviceApi';
import { useParams } from "react-router-dom";

const DeviceLogs = () => {
  let { mob } = useParams();
  const [data, setData] = useState([])
	useEffect(() => {
		async function fetchData() {
			const result = await GetInLogs(mob);
			setData(result.data);
		}
		fetchData();
	}, [])
  return (
    <>
        <Layout>
            <ListComponent 
                title= "Recieve Message Logs"
                addLink={""}
                rowData={data}
                tableColumnsList={InLogsTableList}
            />
        </Layout>
    </>
  )
}

export default DeviceLogs