import * as React from 'react';
import Layout from '../Layout';
import ListWithActionComponent from "../../../components/crud/ListWithActionComponent";

const DevicesList = () => {

  return (
    <>
      <Layout>
            <ListWithActionComponent 
              title={"Device"}
              addLink={"/device/add"}
            />
      </Layout>
    </>
  )
}

export default DevicesList