import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '../hooks/ProtectedRoute';
import Login from '../pages/login/Login';
import ErrorPage from '../pages/default/ErrorPage';
import AdminDashboard from '../pages/authorised/dashboards/AdminDashboard';
import CompanyList from '../pages/authorised/company/CompanyList';
import CompanyAdd from '../pages/authorised/company/CompanyAdd';
// please create a list and use map function for Routes rather than adding one by one in the list
import AdminList from '../pages/authorised/admin/AdminList';
import AdminAdd from '../pages/authorised/admin/AdminAdd';
import ChangePassword from '../pages/authorised/common/changePassword';

import CustomerAdd from '../pages/authorised/customer/CustomerAdd';
import CustomerList from '../pages/authorised/customer/CustomerList';
import DevicesList from '../pages/authorised/customer/DevicesList';
import DeviceLogs from '../pages/authorised/customer/DeviceLogs';
import Subscription from '../pages/authorised/customer/Subscription';
import ApiSettings from '../pages/authorised/customer/ApiSettings';

const Routers = () => {
  return(
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/404" element={<ErrorPage />} />
          <Route path="/login" element={<Login />} />


          <Route path="/change-password" element={<ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>} />
          <Route path="/company" element={<ProtectedRoute>
            <CompanyList />
          </ProtectedRoute>} />
          <Route path="/company/add" element={<ProtectedRoute>
            <CompanyAdd />
          </ProtectedRoute>} />

          <Route path="/dashboard" element={<ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute>
            <AdminList />
          </ProtectedRoute>} />
          <Route path="/admin/add" element={<ProtectedRoute>
            <AdminAdd />
          </ProtectedRoute>} />

          <Route path="/customer" element={<ProtectedRoute>
            <CustomerList />
          </ProtectedRoute>} />
          <Route path="/customer/add" element={<ProtectedRoute>
            <CustomerAdd />
          </ProtectedRoute>} />
          <Route path="/customer/devices" element={<ProtectedRoute>
            <DevicesList />
          </ProtectedRoute>} />
          <Route path="/device/logs/:mob" element={<ProtectedRoute>
            <DeviceLogs />
          </ProtectedRoute>} />
          <Route path="/customer/subscription/:id" element={<ProtectedRoute>
            <Subscription />
          </ProtectedRoute>} />
          <Route path="/customer/api-settings" element={<ProtectedRoute>
            <ApiSettings />
          </ProtectedRoute>} />
        </Routes>      
    </BrowserRouter>
  )
}

export default Routers; 