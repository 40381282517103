import React from 'react'
import { useEffect, useState } from 'react';
import Layout from '../Layout';
import ListComponent from '../../../components/crud/ListComponent';
import { customerTableList } from '../../../utils/dataTableList/cutomer';
import { GetCustomerList } from '../../../services/CutomerApi';

const CustomerList = () => {
    const [data, setData] = useState([])
    useEffect(() => {
		async function fetchData() {
			const result = await GetCustomerList();
      console.log(result.length);
			setData(result);
		}
		fetchData();
	}, [])
  return (
    <>
        <Layout>
            <ListComponent 
                title= "Customer"
                addLink={"/customer/add"}
                rowData={data}
                tableColumnsList={customerTableList}
            />
        </Layout>
    </>
  )
}

export default CustomerList