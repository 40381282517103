import { AxiosGet, AxiosPost, AxiosDelete } from "../utils/axios/AxiosCall";
import {
  DEVICE_URL,
  ADD_DEVICE_URL,
  SCAN_DEVICE_REQUEST,
  QR_CODE_REQUEST,
  GET_LOGS_URL,
  SEND_WHATSAPP_MSG_URL,
} from "../configs/APIConfig";

/***
 * This method will add new mobile number to ssytem
 */
export const AddDevice = async (mobileNumber, callBackUrl) => {
  try {
    let postData = {
      id: sessionStorage.getItem("id"),
      companyId: sessionStorage.getItem("companyId"),
      mobileNumber: mobileNumber,
      callbackUrl: callBackUrl,
    };
    const result = await AxiosPost(ADD_DEVICE_URL, postData);
    if (result.status === 200) {
      return result;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

/***
 * This method will add new mobile number to ssytem
 */
export const ListDevice = async () => {
  try {
    const result = await AxiosGet(
      DEVICE_URL + "/" + sessionStorage.getItem("id")
    );
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const ScanInitRequest = async (mobile) => {
  try {
    const postData = {
      mobileNumber: mobile,
      id: sessionStorage.getItem("id"),
    };
    const result = await AxiosPost(SCAN_DEVICE_REQUEST, postData);
    if (result.status === 200) {
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const QrCodeRequest = async (mobile) => {
  try {
    const postData = {
      mobileNumber: mobile,
      id: sessionStorage.getItem("id"),
    };
    const result = await AxiosPost(QR_CODE_REQUEST, postData);
    if (result.status === 200) {
      console.log(result);
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const GetInLogs = async (mobile) => {
  try {
    const result = await AxiosGet(GET_LOGS_URL.replace("[mobile]", mobile));
    if (result.status === 200) {
      console.log(result);
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const SendWhatsApp = async (senderMob, recieverMob, msg) => {
  try {
    const postData = {
      recieverNumber: recieverMob,
      message: msg,
    };
    const result = await AxiosPost(
      SEND_WHATSAPP_MSG_URL.replace("[mobile]", senderMob),
      postData
    );
    if (result.status === 200) {
      console.log(result);
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};


export const DeleteDevice = async (clientId) => {
  try {
    const result = await AxiosDelete(DEVICE_URL+"/"+clientId);
    if (result.status === 200) {
      console.log(result);
      return result.data;
    } else {
      return result;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
}