import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export const  customerTableList = [
    { field: 'id', headerName: 'S No.', width: 50 },
    { field: 'firstName',headerName: 'Name', valueGetter: (value, row) => {
      return `${row.firstName || ''} ${row.lastName || ''}`;
    }, width: 250 },
    { field: 'mobileNumber', headerName: 'Mobile Number', width: 150 },
    { field: 'emailId', headerName: 'Email Id', width: 250 },
    { field: 'status', headerName: 'Status', valueGetter: (value, row) => {
        return `${row.status === true ? "Active" : 'Inactive'}`;
      }, width: 150 },
      {
        field: 'action',
        headerName: 'Action',
        width: 180,
        sortable: false,
        disableClickEventBubbling: true,
        
        renderCell: (params) => {
            const onClick = (e) => {
              const currentRow = params.row;
              return alert(JSON.stringify(currentRow, null, 4));
            };
            const url="/customer/subscription/"+params.row.uuid;
            
            return (
              <Stack direction="row" spacing={2}>
                <Link to={url}><Button variant="outlined" color="warning" size="small">Subscription</Button></Link>
              </Stack>
            );
        },
      }
];