import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar   } from "react-pro-sidebar";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import BoyIcon from '@mui/icons-material/Boy';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import WebhookIcon from '@mui/icons-material/Webhook';
import { Link } from 'react-router-dom';
import { COMPANY_ROLE, ADMIN_ROLE, CUSTOMER_ROLE } from '../../configs/SettingConstants';

const SideMenu = () => {
  const isCompany = sessionStorage.getItem('role') === COMPANY_ROLE ? true:false;
  const isAdmin = sessionStorage.getItem('role') === ADMIN_ROLE? true: false;
  const isCustomer = sessionStorage.getItem('role') === CUSTOMER_ROLE? true: false;
  return (
      <Sidebar className="app">
        <Menu>
          <MenuItem  className="menu1">
            <h2>
              My Messages
            </h2>
          </MenuItem>
          <MenuItem component={<Link to="/dashboard" className="link" />}  icon={<GridViewRoundedIcon />}> Dashboard </MenuItem>
          {
            isAdmin ? (
              <>
              <MenuItem component={<Link to="/admin" className="link" />}  icon={<BoyIcon />}> Admin </MenuItem>
              <MenuItem component={<Link to="/company" className="link" />}  icon={<CorporateFareIcon />}> Company </MenuItem>
              </>
            ) : ("")
          }
          {
            isCompany ? (
              <MenuItem component={<Link to="/customer" className="link" />}  icon={<FaceRetouchingNaturalIcon />}> Customer </MenuItem>
            ) : ("")
          }
          {
            isCustomer ? (
              <>
                <MenuItem component={<Link to="/customer/devices" className="link" />}  icon={<SendToMobileIcon />}> Devices </MenuItem>
                <MenuItem component={<Link to="/customer/api-settings" className="link" />}  icon={<WebhookIcon />}> API Settings </MenuItem>
              </>
            ):("")
          }
         
        </Menu>
      </Sidebar>
  )
}

export default SideMenu