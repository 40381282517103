/** We need to move base url to env file */
//export const API_BASE_URL   = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL   = 'https://api.mymessages.in/'//'http://localhost:5020/';
export const LOGIN = 'login';
export const USER_URL       = 'users';
export const COMPANY_URL    = 'company';
export const ADMIN_URL    = 'admin';
export const CUSTOMER_URL    = 'customer';
export const DEVICE_URL    = 'device';
export const ADD_DEVICE_URL = 'device/add';
export const SCAN_DEVICE_REQUEST = 'device/scan';
export const QR_CODE_REQUEST = 'device/qr';

export const GET_LOGS_URL = 'device/[mobile]/logs';
export const SEND_WHATSAPP_MSG_URL = 'device/[mobile]/send-message';
export const CHANGE_PASS_URL = 'profile/[id]/change-password';

export const CUSTOMER_SUBSCRIPTION_URL = "customer/[customerId]/subscription";
export const CUSTOMER_API_KEY_GEN_URL = "customer/[customerId]/apikey";