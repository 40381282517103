import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QRCode from "react-qr-code";

export default function ShoWQRCodeModel(props) {

  const handleClose = () => {
    props.setShowqr(false);
  };

  return (
    <React.Fragment>
        <Dialog
            open={props.showqr}
            onClose={handleClose}
            PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                console.log('close it and check');
                handleClose();
            },
            }}
        >
        <DialogTitle>Scan QR Code</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          
          <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={props.qrcode}
              viewBox={`0 0 256 256`}
            />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Colse</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
